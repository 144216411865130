import { createStore } from "vuex";
const serverUrl = "https://andrewesmat.easetasks.com/";
// Api_Url: "http://localhost/andrew/public/",
export default createStore({
  state: {
    ServerUrl: serverUrl,
    Api_Url: serverUrl + "Theindex.php",
    Api_Url2: serverUrl + "portfolioupload.php",
    Api_Url3: serverUrl + "reviewsupload.php",
    CurrentWidth: window.innerWidth,
    LoaderIndex: 0,
    SideMenuIndex: 0,
  },
  getters: {},
  mutations: {
    OpenLoader() {
      this.state["LoaderIndex"] = 1;
    },
    CloseLoader() {
      this.state["LoaderIndex"] = 0;
    },
    OpenSideMenu() {
      this.state["SideMenuIndex"] = 1;
    },
    CloseSideMenu() {
      this.state["SideMenuIndex"] = 0;
    },
  },
  actions: {
    ResizePage: ({ state }, payload) => {
      state.CurrentWidth = payload["WindowWidth"];
    },
    MoveToSlide: ({ state }, payload) => {
      let NewSlide = payload["NewSlide"];
      state.SideMenuIndex = 0;
      $("html, body").animate(
        { scrollTop: $("#" + NewSlide).offset().top },
        200,
        "linear",
        function () {
          // setTimeout(() => {
          //   // state.LandingView["AcceptScroll"] = 1;
          //   // state.LandingView["CurrentSlide"] = NewSlide;
          // }, 600);
        }
      );
    },
  },
  modules: {},
});
