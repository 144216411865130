<template>
  <div class="col-12" id="MainNav">
    <h1 id="AppInfo" @click="this.$router.push({ name: 'home' })">
      Andrew <strong>Esmat</strong>
    </h1>
    <div id="NavActions" v-if="this.$store.state['CurrentWidth'] > 767">
      <ul class="col-12">
        <li
          v-for="Action in this.NavActions"
          :key="Action"
          @click="this.$router.push({ name: Action['href'] })"
        >
          {{ Action["Name"] }}
        </li>
      </ul>
    </div>
    <font-awesome-icon
      icon="fa-solid fa-bars"
      v-else
      @click="this.$store.commit('OpenSideMenu')"
    />
    <Transition name="slide">
      <div
        class="col-12"
        id="SideMenu"
        v-if="
          this.$store.state['CurrentWidth'] <= 767 &&
          this.$store.state['SideMenuIndex'] == 1
        "
      >
        <ul>
          <li @click="this.$store.commit('CloseSideMenu')">
            <font-awesome-icon icon="fa-solid fa-x" />
          </li>
          <li
            v-for="Action in this.NavActions"
            :key="Action"
            @click="
              this.$store.commit('CloseSideMenu');
              this.$router.push({ name: Action['href'] });
            "
          >
            {{ Action["Name"] }}
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>
<script>
export default {
  name: "MainNav",
  data() {
    return {
      NavActions: [
        { Name: "Portfolio", href: "portfolio" },
        { Name: "Reviews", href: "reviews" },
        // { Name: "Hire Me", href: "home" },
      ],
    };
  },
  created() {},
};
</script>
<style scoped lang="scss">
#MainNav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  height: 4rem;
  #AppInfo {
    font-size: 1.3rem;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    strong {
      color: white;
      font-weight: 400;
    }
  }
  #NavActions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    ul {
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      li {
        cursor: pointer;
        color: white;
        font-size: 1rem;
        padding: 0.25rem 0.5rem;
        list-style: none;
        transition: ease all 500ms;
      }
      li:hover {
        // margin-top: -0.75rem;
        transform: translate(0, -8px);
      }
    }
  }
  svg {
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }
  #SideMenu {
    background-color: black;
    color: white;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    ul {
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      color: white;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      li {
        font-size: 2rem;
        padding: 0.5rem 0;
        cursor: pointer;
        transition: ease all 500ms;
        list-style: none;
      }
      li:hover {
        transform: translate(-8px, 0px);
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.slide-enter-from {
  width: 0;
}
.slide-enter-to {
  width: 100%;
}
.slide-enter-active {
  transition: all 500ms ease;
}
.slide-leave-from {
  width: 100%;
}
.slide-leave-to {
  width: 0;
}
.slide-leave-active {
  transition: all 300ms ease;
}
</style>
