import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import('@/views/PortfolioView.vue')
  },
  {
    path: '/system',
    name: 'system',
    component: () => import('@/views/SystemView.vue')
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: () => import('@/views/ReviewView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'view404',
    component: () => import('@/views/View404.vue')
  },
  // ,
  // {
  //   path: '/article/:article_id',../views/404View.vue
  //   name: 'article',
  //   component: () => import('../views/AnArticleView.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
